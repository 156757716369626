@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.IconButton {
  height: 32px;
  display: inline-flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  padding-top: 7px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.IconButton > .icon > * {
  height: 24px;
  width: 24px;
  margin-right: 20px;
  font-size: 20px;
  color: white;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}

.IconButton > .label {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.ChooseDevicePage {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.ChooseDevicePage > .heading {
  font-size: 16px;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
}

.ChooseDevicePage .IconButton {
  min-width: 140px;
}

.ChooseDevicePage .IconButton + .IconButton {
  margin-top: 16px;
}

.CodeUnlockedPage {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.CodeUnlockedPage > .heading {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 8px;
}

.CodeUnlockedPage > .flavor-text {
  color: #606060;
  font-size: 15px;
  line-height: 22px;
}

.codebox-title {
  margin-top: 24px;
  font-size: 15px;
  font-weight: 700;
}

.CodeBox {
  align-items: stretch;
  margin-top: 4px;
  padding-left: 80px;
  padding-right: 80px;
}

.copy-button {
  cursor: pointer;
  color: #ec008c;
  text-decoration: underline;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 700;
}

.copy-button:hover {
  color: #ee6bba;
}

.CodeBox {
  padding: 10px 0;
  border: 1px solid lightgray;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CodeBox .code-text {
  letter-spacing: 0.65em;
  font-weight: 600;
  padding-left: 24px;
  padding-right: 24px;
}

.RootContent {
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

