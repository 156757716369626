.ChooseDevicePage {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.ChooseDevicePage > .heading {
  font-size: 16px;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
}

.ChooseDevicePage .IconButton {
  min-width: 140px;
}

.ChooseDevicePage .IconButton + .IconButton {
  margin-top: 16px;
}
