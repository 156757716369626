.CodeUnlockedPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.CodeUnlockedPage > .heading {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 8px;
}

.CodeUnlockedPage > .flavor-text {
  color: #606060;
  font-size: 15px;
  line-height: 22px;
}

.codebox-title {
  margin-top: 24px;
  font-size: 15px;
  font-weight: 700;
}

.CodeBox {
  align-items: stretch;
  margin-top: 4px;
  padding-left: 80px;
  padding-right: 80px;
}

.copy-button {
  cursor: pointer;
  color: #ec008c;
  text-decoration: underline;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 700;
}

.copy-button:hover {
  color: #ee6bba;
}
