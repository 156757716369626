.CodeBox {
  padding: 10px 0;
  border: 1px solid lightgray;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CodeBox .code-text {
  letter-spacing: 0.65em;
  font-weight: 600;
  padding-left: 24px;
  padding-right: 24px;
}
