.IconButton {
  height: 32px;
  display: inline-flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  padding-top: 7px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.IconButton > .icon > * {
  height: 24px;
  width: 24px;
  margin-right: 20px;
  font-size: 20px;
  color: white;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.IconButton > .label {
  font-size: 16px;
  font-weight: 600;
  color: white;
}
